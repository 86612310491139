import React, {Fragment} from 'react'
import { Helmet } from 'react-helmet'
// Import global styles needed in document
require('../styles/seed.less')

let headline = "PaverOps: Paving project planning and coordination"
let description = "PaverOps enables planning, collaboration, and coordination between cities, counties, and utility providers to reduce project planning and construction obstacles, costs, and impacts on the public. By providing direct access to robust underground utility infrastructure GIS data, upcoming utility project sites, and paving plans and moratoriums, PaverOps saves organizations resources while also helping to extend the life of an organization's assets."
let url = "https://www.paverops.com"

export default function Layout({ children }) {
  return (
    <Fragment>
      <Helmet>
        <title>PaverOps</title>
        <meta name="description" content={description} />
        <link
          rel="shortcut icon"
          href="/favicon.ico"
          type="image/x-icon"
        />
        <link rel="canonical" href={url} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={headline} />
        <meta name="twitter:site" content={"@"} />
        <meta
          name="twitter:url"
          content={url}
        />
        <meta name="twitter:image" content={url+"/resized-topper.jpg"} />
        <meta name="twitter:description" content={description} />

        <meta property="og:type" content="article" />
        <meta property="og:title" content={headline} />
        <meta property="og:site_name" content={"PaverOps"} />
        <meta property="og:url" content={url}/>
        <meta property="og:image" content={url+"/resized-topper.jpg"} />
        <meta property="og:description" content={description} />

        <script data-schema="Product" type="application/ld+json">{`{
          "@context": "http://schema.org",
          "@type": "Service",
          "brand": "Maranta",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "${url}"
          },
          "name": "${headline}",
          "image": {
            "@type": "ImageObject",
            "url": "${url}/resized-topper.jpg"
          },
          "description": "${description}"
        }`}</script>

       </Helmet>
      {children}
    </Fragment>
  )
}